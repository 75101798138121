import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import {
    Link,
    Trans,
    useTranslation,
    useI18next,
    I18nextContext
  } from "gatsby-plugin-react-i18next";

import styled from "styled-components"
import { ContainerWithBackground} from '../styles/global-styles';
import backgroundImage from '../images/background-test-yourself.jpg';

import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';
import Quiz from './quiz-find-your-companies-behavioural-type';

const Container = styled.div`
    width: 100%;
    // padding-top: 60px;
    text-align: center;
    // background: #ACD433;
    // background: #2c466b;
    // color: wheat;
    // margin-top: 15px;
    margin: 0 auto;
`

const Section = styled.div`
    
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props=>props.$backgroundColour};
    color: ${props=>props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
    
        
    @media screen and (max-width: 767px) {
        padding-top: 73px;
    }
`

const ContentInner = styled.div`
    width: 73.306%;
    width: 95%;
    margin: 0 auto;
`

const Content = styled.div`
    margin: 0 auto;
    margin-top: 40px;
    width: 90%;
    max-width: 90%;
    max-width: 1372px;
    background: white;
    
    p { 
        
        font-size: 20px;
        line-height: 1.4;
        margin-top: 14px;
        font-weight: 400;
        white-space: pre-wrap;
        margin-bottom: 14px;
        text-align: left;
    }
`

const AvatarsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 25px;
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
`

const Name = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
`

const Titles = styled.p`
    font-size: 16px;
    text-align: center;
`



const FindYourCompanysBehaviouralTypeContent = () => {
    const {t} = useI18next();

    return (
        <ContainerWithBackground backgroundImage={backgroundImage}>
            <ContentWrapper >
                <SectionHeader title={t("TEST-YOURSELF.YOUR-COMPANYS-BEHAVIOURAL-TYPE")}  withBackground={false}/>
                <Content>
                    <ContentInner>
                        <Quiz />
                    </ContentInner>
                </Content>
            </ContentWrapper>
        </ContainerWithBackground>
    )
}

export default FindYourCompanysBehaviouralTypeContent