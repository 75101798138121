import React, { useState, useEffect } from 'react';
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import styled from "styled-components"
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { PieChart } from 'react-minimal-pie-chart';

import { animateScroll as scroll } from 'react-scroll'

const useStyles = makeStyles({
  formGroupRoot: {
    flexDirection: 'row',
    justifyContent: 'center',
     ['@media screen and (max-width: 768px)']: {
        justifyContent: 'flex-start',
    },
    
    label: {
        textAlign: "left",
    },
  },
  controlLabel: {
        width: '30%',
        ['@media screen and (max-width: 768px)']: {
            width: '48%',
        },
  },
  pieChartWrapper: {
        alignSelf: 'flex-start',
        marginTop: 15,
        
  }
});

const Instructions = styled.div`
    && {
        margin-bottom: 40px;
        opacity: 1;
        // background: #2c466b;
        padding: 10px 20px;
        // max-height: 105px;
        
        @media screen and (max-width: 1220px) {
              max-height: initial;
              width: 100%;
        }
        
        p {
            font-size: 20px;
            // color: white;
            text-align: center;
        }
        
    }

    
    &.hidden {
        opacity: 0;
        max-height: 0;
        margin: 0;
        padding: 0;
        visibility: hidden;
        transition: all 1s ease;
    }
`

const Characteristics = styled.div`
    margin-top: 40px;
    text-align: center;
    font-size: 30px;
    
    @media screen and (max-width: 767px) {
        font-size: 22px;
    }
`

const UL = styled.ul`
    text-align: left;
    
    li {
        font-size: 20px;
        @media screen and (max-width: 767px) {
            font-size: 18px;
            margin-top: 8px;
        }
    }
`

const OL = styled.ol`
    text-align: left;
    
    li {
        font-size: 20px;
        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
`

const H2 = styled.h2`
    margin-top: 32px;
    font-size: 28px;
    text-align: center;
    @media screen and (max-width: 767px) {
        margin-top: 0;
        font-size: 24px;
    }
`
    
const H3 = styled.h3`
    font-size: 30px;
    margin-top: 24px;
    margin-bottom: 8px;
    text-align: center;
    @media screen and (max-width: 767px) {
        text-align: left;
        font-size: 22px;
    }
`

    
const H4 = styled.h4`
    font-size: 36px;
    margin-top: 32px;
    margin-bottom: 16px;
    text-align: center;
    @media screen and (max-width: 767px) {
        font-size: 22px;
    }
`

const SubHeader = styled.h4`
    text-align: center;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 10px;
    @media screen and (max-width: 1220px) {
        font-size: 20px;
        margin-top: 0;
    }
`

const ErrorMessage = styled.p`
    && {
        font-size: 16px;
        text-align: center;
        height: 22px;
        margin-top: 5px;
    }
    
`

const ResultWrapper = styled.div`
    display: flex;
    
    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`

const ResultColumn = styled.div`
    flex: 1 1 0%;
    width: 32%;
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    
    @media screen and (max-width: 767px) {
        width: 100%;
    }
`

const CallToAction = styled.div`
    width: 75%;
    margin: 64px auto auto;
    
    @media screen and (max-width: 767px) {
        width: 95%;
    }
`


const coreValuesEN = [
    { 
        valueName: "Achievement",
        category: "Instinctive",
    },
    {
        valueName: "Empathy",
        category: "Social",
    },
    {
        valueName: "Agility",
        category: "Instinctive",
    },
    {
        valueName: "Flexibility",
        category: "Rational",
    },
    {
        valueName: "Helpfulness",
        category: "Social",
    },
    {
        valueName: "Competitiveness",
        category: "Instinctive",
    },
    {
        valueName: "Ambition",
        category: "Instinctive",
    },
    {
        valueName: "Fairness",
        category: "Social",
    },
    {
        valueName: "Courage",
        category: "Instinctive",
    },
    {
        valueName: "Learning",
        category: "Rational",
    },
    {
        valueName: "Appreciation",
        category: "Social",
    },
    {
        valueName: "Objectivity",
        category: "Rational",
    },
    {
        valueName: "Passion",
        category: "Social"
    },
    {
        valueName: "Openness",
        category: "Rational",
    },
    {
        valueName: "Long-term thinking",
        category: "Rational",
    },
    {
        valueName: "Innovation",
        category: "Rational",
    },
    {
        valueName: "Power",
        category: "Instinctive",
    },
    {
        valueName: "Safety",
        category: "Instinctive",
    },
    {
        valueName: "Teamwork",
        category: "Social",
    },
    {
        valueName: "Self-discipline",
        category: "Rational",
    },
    {
        valueName: "Trust",
        category: "Social",
    }
]


const coreValuesHU = [
    { 
        valueName: "Teljesítmény",
        category: "Ösztönös",
    },
    {
        valueName: "Empátia",
        category: "Közösségi",
    },
    {
        valueName: "Agilitás",
        category: "Ösztönös",
    },
    {
        valueName: "Rugalmasság",
        category: "Racionális",
    },
    {
        valueName: "Segítőkészség",
        category: "Közösségi",
    },
    {
        valueName: "Versengés",
        category: "Ösztönös",
    },
    {
        valueName: "Ambíció",
        category: "Ösztönös",
    },
    {
        valueName: "Méltányosság",
        category: "Közösségi",
    },
    {
        valueName: "Bátorság",
        category: "Ösztönös",
    },
    {
        valueName: "Tanulás",
        category: "Racionális",
    },
    {
        valueName: "Megbecsülés",
        category: "Közösségi",
    },
    {
        valueName: "Objektivitás",
        category: "Racionális",
    },
    {
        valueName: "Szenvedély",
        category: "Közösségi"
    },
    {
        valueName: "Nyitottság",
        category: "Racionális",
    },
    {
        valueName: "Hosszú távú gondolkodás",
        category: "Racionális",
    },
    {
        valueName: "Innováció",
        category: "Racionális",
    },
    {
        valueName: "Hatalom",
        category: "Ösztönös",
    },
    {
        valueName: "Biztonság",
        category: "Ösztönös",
    },
    {
        valueName: "Csapatmunka",
        category: "Közösségi",
    },
    {
        valueName: "Önfegyelem",
        category: "Racionális",
    },
    {
        valueName: "Bizalom",
        category: "Közösségi",
    }
]

const colours = [
        'rgb(245, 222, 179)',
        'rgb(172, 212, 51)',
        'rgb(255,128,0)',
    ]
    
const REQUIRED_ANSWERS = 5

const QuizFindYourCompanysBehaviouralType = () => {
    
    const {t} = useI18next();
    const context = React.useContext(I18nextContext);
    const language = context.language;

    const [coreValues, setCoreValues] = useState((language === "hu") ? coreValuesHU : coreValuesEN)
    const [gameOver, setGameOver] = useState(true);
    const [selectedValues, setSelectedValues] = useState([]);
    const [shouldDisableCheckbox, setShouldDisableCheckbox] = useState(false);
    const [summary, setSummary] = useState(null);
    const [revealResult, setRevealResult] = useState(false);
    
    const classes = useStyles()
    
    function startGame () {
        setGameOver(false);
    }
    
    function reset () {
        setGameOver(true);
        setSelectedValues([]);
        setSummary(null)
        setRevealResult(false)
    }
    
    function handleChange (event) {
        // console.log(event.target.checked, event.target.value)
        let list = [...selectedValues, coreValues[event.target.value]]
        
        event.target.checked ? addItem(event.target.value) : removeItem(event.target.name)
    }
    
    function addItem (value) {
        let newList = [...selectedValues, coreValues[value]]
        setSelectedValues(newList)
    }
    
    function removeItem (value) {
        let newList = selectedValues.filter(item => item.valueName !== value)
        setSelectedValues(newList)
    }
    
    function reveal () {
        setRevealResult(true)
        scroll.scrollToTop();
    }
    
    useEffect(()=>{
        // console.log(selectedValues)
        const countByCategory = count(selectedValues, function (item) {
            return item.category
        });
        let countByCategoryArray = Object.keys(countByCategory)
                   .map((key, index) => ({name: String(key), value: Number(countByCategory[key]), color: colours[index]}));
        setSummary(countByCategoryArray);
        // console.log(countByCategory, countByCategoryArray);
    }, [selectedValues])
    
    
    function count (ary, classifier) {
        classifier = classifier || String;
        return ary.reduce(function (counter, item) {
            var p = classifier(item);
            counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
            return counter;
        }, {})
    };
    
    // function disableCheckbox (element, numberOfRequiredAnswers) {
    //     return ((selectedValues.length) >= REQUIRED_ANSWERS && !event.target.checked)
    // }
    // const shouldDisableCheckbox = value => {
    //   // either from props: const { maxAllowed } = this.props
    //   // or from state: const { maxAllowed } = this.state
    //   // or just a constant:
    //   const maxAllowed = 3
    //   const { checked } = this.state
    //   return checked.length >= maxAllowed && checked.indexOf(value) === -1
    // }
    
    
    
    return (
        <React.Fragment>
            { gameOver ? (
                <Box>  
                    <Instructions >
                        <p>{t("TEST-YOURSELF.YOUR-COMPANYS-BEHAVIOURAL-TYPE-FIND-OUT")}</p>
                        <p>{t("TEST-YOURSELF.YOUR-COMPANYS-BEHAVIOURAL-TYPE-CHECK-FIVE")}</p>
                    </Instructions>
                    <Box mt={5} pb={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            endIcon={<NavigateNextIcon/>}
                            className='next'
                            onClick={startGame}
                        >
                        {t("TEST-YOURSELF.START-THE-QUIZ")}
                        </Button>
                    </Box>
                </Box>
                )
            : null }
        
            { !gameOver && !revealResult ? (
                <div>
                    <H2>{t("TEST-YOURSELF.YOUR-COMPANYS-VALUES")}</H2>
                    <SubHeader>{t("TEST-YOURSELF.PLEASE-SELECT-FIVE")}</SubHeader>
                    <FormGroup className={classes.formGroupRoot}>
                    {
                        coreValues.map((value, key)=>{
                            return (
                                <FormControlLabel
                                    key={value.valueName}
                                    className={classes.controlLabel}
                                    control={
                                        <Checkbox 
                                            // checked={state.checkedA} 
                                            onChange={handleChange} 
                                            name={value.valueName} 
                                            value={key}
                                            // disabled={(selectedValues.length) >= REQUIRED_ANSWERS ? true : false}
                                        />
                                    }
                                    label={value.valueName}
                                />
                            )
                        })
                    }
                    </FormGroup>
                    <ErrorMessage>
                        {
                            // (selectedValues.length) < REQUIRED_ANSWERS && `You still need to pick ${REQUIRED_ANSWERS - selectedValues.length} values to complete your 5 votes.`
                            (selectedValues.length) < REQUIRED_ANSWERS && t("TEST-YOURSELF.YOU-STILL-NEED", {count: REQUIRED_ANSWERS - selectedValues.length})
                        }
                        {
                            // (selectedValues.length) > REQUIRED_ANSWERS && `Please remove ${(selectedValues.length - REQUIRED_ANSWERS)} values to complete your 5 votes.`
                            (selectedValues.length) > REQUIRED_ANSWERS && t("TEST-YOURSELF.PLEASE-REMOVE", {count: selectedValues.length - REQUIRED_ANSWERS})
                        }
                    </ErrorMessage>
                    {
                        <Box mt={0} pb={5}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<NavigateNextIcon/>}
                                className='next'
                                onClick={reveal}
                                disabled={(selectedValues.length) == REQUIRED_ANSWERS ? false : true}
                            >
                                {t("TEST-YOURSELF.REVEAL-RESULT")}
                            </Button>
                        </Box>
                    }
                </div>
                ) : null
            }
            
            {
            //     summary && !revealResult && (selectedValues.length == REQUIRED_ANSWERS) ? (
            //         <Box mt={5}>
            //             <Button
            //                 variant="contained"
            //                 color="primary"
            //                 endIcon={<NavigateNextIcon/>}
            //                 className='next'
            //                 onClick={reveal}
            //             >
            //                 {"Reveal result"}
            //             </Button>
            //         </Box>
            //     ) : null
            }
            {
                revealResult ? (
                    <div>
                        <H2>{t("TEST-YOURSELF.YOUR-COMPANYS-BEHAVIOURAL-TYPE-MIX")}</H2>
                        <SubHeader>{t("TEST-YOURSELF.AS-YOU-ASSESSED")}</SubHeader>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" m="auto" >
                           <Box className={classes.PieChartWrapper} maxWidth={300} width="75%">
                               <PieChart 
                                    data={summary} 
                                    label={({ dataEntry }) => dataEntry.name} 
                                    labelStyle={{fontSize: 5.5}}
                                    animate={true}
                                    animationDuration={1000}
                                    
                                />
                            </Box>
                            <Box width="100%">
                                <Characteristics textAlign="center">{t("TEST-YOURSELF.CHARACTERISTICS-OF-THE-DIFFERENT-COMPANY-BEHAVIOURAL-TYPES")}</Characteristics>
                                
                                
                                <ResultWrapper display="flex">
                                    <ResultColumn>
                                    <H3>{t("TEST-YOURSELF.INSTINCTIVE-ORGANIZATION")}</H3>
                                        <UL>
                                            <li>{t("TEST-YOURSELF.INSTINCTIVE-ORGANIZATION-TYPE-1")}</li>
                                            <li>{t("TEST-YOURSELF.INSTINCTIVE-ORGANIZATION-TYPE-2")}</li>
                                            <li>{t("TEST-YOURSELF.INSTINCTIVE-ORGANIZATION-TYPE-3")}</li>
                                        </UL>
                                    </ResultColumn>
                                    <ResultColumn>
                                    <H3>{t("TEST-YOURSELF.SOCIAL-ORGANIZATION")}</H3>
                                        <UL>
                                            <li>{t("TEST-YOURSELF.SOCIAL-ORGANIZATION-TYPE-1")}</li>
                                            <li>{t("TEST-YOURSELF.SOCIAL-ORGANIZATION-TYPE-2")}</li>
                                            <li>{t("TEST-YOURSELF.SOCIAL-ORGANIZATION-TYPE-3")}</li>
                                        </UL>
                                    </ResultColumn>
                                    <ResultColumn>
                                    <H3>{t("TEST-YOURSELF.RATIONAL-ORGANIZATION")}</H3>
                                        <UL>
                                            <li>{t("TEST-YOURSELF.RATIONAL-ORGANIZATION-TYPE-1")}</li>
                                            <li>{t("TEST-YOURSELF.RATIONAL-ORGANIZATION-TYPE-2")}</li>
                                            <li>{t("TEST-YOURSELF.RATIONAL-ORGANIZATION-TYPE-3")}</li>
                                        </UL>
                                    </ResultColumn>
                                </ResultWrapper>
                            </Box>
                        </Box>
                      
                        <CallToAction>
                       
                            <H4>{t("TEST-YOURSELF.ASK-YOURSELF")}:</H4>
                            <OL>
                                <li>{t("TEST-YOURSELF.ASK-YOURSELF-QUESTION-1")}</li>
                                <li>{t("TEST-YOURSELF.ASK-YOURSELF-QUESTION-2")}</li>
                                <li>{t("TEST-YOURSELF.ASK-YOURSELF-QUESTION-3")}</li>
                                <li>{t("TEST-YOURSELF.ASK-YOURSELF-QUESTION-4")}</li>
                                <li>{t("TEST-YOURSELF.ASK-YOURSELF-QUESTION-5")}</li>
                            </OL>
                           
                            {
                                (language === "en") && 
                                <p><strong><Link to="/contact">Contact us</Link> if you want to learn more about how to use this analytical approach to future-proof your organization, and to help you make better business and personal decisions.</strong></p>

                            }

                            {
                                (language === "hu") && 
                                <p><strong>Amennyiben vállalatának fejlesztéséhez további információra van szüksége a módszertanra, annak alkalmazására vonatkozóan, kérjük, lépjen velünk <Link to="/contact">kapcsolatba</Link> a megadott elérhetőségeken.</strong></p>
                            }

                        </CallToAction>
                        <Box mt={5} pb={5}>
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<NavigateNextIcon/>}
                                className='next'
                                onClick={reset}
                            >
                                {t("TEST-YOURSELF.RESET-QUIZ")}
                            </Button>
                        </Box>
                    </div>
                ) : null
            }
        </React.Fragment>
    )
}

export default QuizFindYourCompanysBehaviouralType