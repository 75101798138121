import React from "react"
import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AreYouBiasedContent from "../../components/find-your-companys-behavioural-type-content"



const FindYourCompanysBehaviouralType = () => {
  const {t} = useI18next();

  return (
    <Layout>
      <SEO title={t("MENU.FIND-YOUR-COMPANYS-BEHAVIOURAL-TYPE")} />
      <AreYouBiasedContent/>
    </Layout>
  )
}

export default FindYourCompanysBehaviouralType